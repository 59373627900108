





























import { Component, Prop, Vue } from "vue-property-decorator";
import CartCommissionItemListItem from "./CartCommissionItemListItem.vue";
import { CartCommissionItem } from "@/store/modules/cart/types";
import { Article } from "@/types/shop/article";

/**
 * CartCommissionItemList component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionItemListItem,
    },
})
export default class CartCommissionItemList extends Vue {
    @Prop({ type: Array, required: true })
    readonly commissionItems!: CartCommissionItem<Article>[];
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
}
