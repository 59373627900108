











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CartCommissionList from "@/components/shop/cart/commission/CartCommissionList.vue";
import ArticlePriceLabel from "@/components/shop/article/price/ArticlePriceLabel.vue";
import { OwnOrder } from "@/types/shop/order";

/**
 * OrderListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        CartCommissionList,
        ArticlePriceLabel,
    },
})
export default class OrderListItem extends Vue {
    @Prop({ type: Object, required: true }) readonly order!: OwnOrder;

    private showTrackingIFrame = false;
}
