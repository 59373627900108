



































import { Component, Prop, Vue } from "vue-property-decorator";
import ArticleListItem from "@/components/shop/article/list/ArticleListItem.vue";
import { Article } from "@/types/shop/article";
import { CartCommissionItem } from "@/store/modules/cart/types";

/**
 * CartCommissionItemListItem component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        ArticleListItem,
    },
})
export default class CartCommissionItemListItem extends Vue {
    @Prop({ type: Object, required: true })
    readonly commissionItem!: CartCommissionItem<Article>;
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    /**
     * redirect to article details page
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private redirectToArticleDetails() {
        this.$router.push({
            name: "shopArticleDetails",
            params: {
                id: this.commissionItem.article.id.toString(),
            },
        });
    }
}
